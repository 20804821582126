@media (max-width: 400px) {
    .img {
        width: 50px;
        height: 50px;
    }

    .doc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    #dropdown-basic-button {
        display: none;
    }
}

@media (min-width: 400px) {
    .img {
        width: 70px;
        height: 70px;
    }

    .doc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    #dropdown-basic-button {
        background-color: #ffffff;
        color: #0c235f;
        padding: 1px;
        border: 1px solid #0c235f;
        cursor: pointer;
    }
}

@media (min-width: 550px) {
    .img {
        width: 100px;
        height: 100px;
    }

    .doc {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    #dropdown-basic-button {
        background-color: #ffffff;
        color: #0c235f;
        padding: 10px;
        border: 1px solid #0c235f;
        cursor: pointer;
    }
}


.img2 {
    width: 45%;
    height: 45%;
}

.p1 {
    color: #0c235f;
}

.ANGARA {
    color: #17347e;
}


.card {
    cursor: pointer;
    box-shadow: 5px 5px 8px #ccd8f6;
    margin-bottom: 15px;
}

.card:hover {
    box-shadow: 5px 5px 5px #0c235f;
    background-color: #e0e7fa;
}

.card-text {
    color: #496fcf;
}