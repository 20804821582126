.icon-upload {
    font-size: 100px;
    color: #A7B9EA;
    cursor: pointer;
}

.text-upload {
    font-size: 12px;
    color: #A7B9EA;
}

.text-barre-upload {
    font-size: 14px;
}

@media (max-width: 435px) {
    #img-upload {
        border: 2px dashed rgb(203, 203, 203);
        height: 200px;
        width: 300px;
        border-radius: 10px;
    }

    .file-upload {
        height: 170px;
        width: 270px;
    }

    #barre-upload {
        width: 300px;
        height: 50px;
        background-color: #0C235F;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-inline: 20px;
        color: white;
    }
}

@media (min-width: 435px) {
    #img-upload {
        border: 2px dashed rgb(203, 203, 203);
        height: 250px;
        width: 400px;
        border-radius: 10px;
    }

    .file-upload {
        height: 230px;
        width: 350px;
    }

    #barre-upload {
        width: 400px;
        height: 70px;
        background-color: #0C235F;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-inline: 20px;
        color: white;
    }
}