.p1 {
    color: #0c235f;
}

.form-control {
    background-color: #ebf1fa;
}

.number {
    height: 32px;
    width: 32px;
    font-size: 16px;
    line-height: 32px;
    margin-right: 10px;
}

.boule {
    background-color: #ffffff;
    border: #bcbdbd solid;
    color: #bcbdbd;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.boule-app {
    background-color: #5a7bcf;
    color: #ffffff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.corde {
    height: 2px;
    width: 30px;
    background-color: #bcbdbd;
}

.corde6 {
    width: 20px;
    background-color: white;
}

.corde-6 {

    width: 50px;
    background-color: white;
}

@media (min-width: 990px) {
    .boule {
        background-color: #ffffff;
        border: #bcbdbd solid;
        color: #bcbdbd;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .boule-app {
        background-color: #5a7bcf;
        color: #ffffff;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}


.lesboules {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
}


.carousel-item {
    transition: transform 0.6s ease-in-out !important;
    backface-visibility: hidden;
    /* Évite l'effet de réduction d'image */
}

.carousel-item img {
    width: 100%;
    /* Garde l'image à une taille constante */
    height: auto;
}

@keyframes crouss1 {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.crouss1 {
    overflow: hidden;
}

.crouss2 {
    white-space: nowrap;
    padding-bottom: 50px;
    animation: 12s crouss1 infinite linear;
}

.crouss2 img {
    height: 120px;
    margin-inline: 40px;
}

.otp-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-input {
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.renv {
    color: white;
    background-color: #5a7bcf;
}