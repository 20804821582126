.succ:hover {
    background-color: rgb(0, 128, 79);
    color: #ffffff;
    border-color: green;
}

#bou1:hover, #bou2:hover, #bou3:hover {
    background-color: rgb(0, 128, 79);
    color: #ffffff;
    border-color: green;
}