.card-hover:hover {
    background-color: transparent;
    box-shadow: 5px 5px 8px #ccd8f6;
}

.preuve-title {
    color: #0c235f;
    text-align: center;
    font-weight: bolder;
}

.text-preuve {
    color: #0c235f;
}


@media (max-width: 400px) {
    .text-preuve {
        color: #0c235f;
        font-size: 12px !important;
    }

    .btn-preuve {
        font-size: 12px;
        width: 120px;
    }

    .preuve-title {
        color: #0c235f;
        text-align: center;
        font-weight: bolder;
        font-size: 15px;
    }

    .commande-preuve {
        font-size: 12px;
    }
}