.img-count {
    height: 50px;
    width: 150;
}

.img-count-cart {
    height: 50px;
    width: 478;
}

.champ-money {
    height: 30px;
    width: 300px;
    border: 1px solid black;
    background-color: white;
    font-size: 13px;
}

.champ-money2 {
    height: 30px;
    width: 250px;
    border: 1px solid black;
    background-color: white;
    font-size: 13px;
}